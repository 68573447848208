/* You can add global styles to this file, and also import other style files */
$button_color: var(--button_color);
$button_text_color: var(--button_text_color);
$primary_color: var(--primary_color);
$primary_footer_background_color: var(--primary_footer_background_color);
$primary_footer_text_color: var(--primary_footer_text_color);
$primary_header_background_color: var(--primary_header_background_color);
$primary_header_text_color: var(--primary_header_text_color);
$secondary_color: var(--secondary_color);
$secondary_footer_background_color: var(--secondary_footer_background_color);
$secondary_footer_text_color: var(--secondary_footer_text_color);
$secondary_header_background_color: var(--secondary_header_background_color);
$secondary_header_text_color: var(--secondary_header_text_color);
$text_primary_color: var(--text_primary_color);
$text_secondary_color: var(--text_secondary_color);
$text-color-content-management:var(--text-color);

@font-face {
    font-family: Oswald-Regular;
    src: url(../public/Font/Oswald/Oswald-Regular.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: Oswald-Bold;
    src: url(../public/Font/Oswald/Oswald-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Oswald-ExtraLight;
    src: url(../public/Font/Oswald/Oswald-ExtraLight.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: Oswald-Light;
    src: url(../public/Font/Oswald/Oswald-Light.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: Oswald-Medium;
    src: url(../public/Font/Oswald/Oswald-Medium.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: Oswald-SemiBold;
    src: url(../public/Font/Oswald/Oswald-SemiBold.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: OpenDyslexic-Regular;
    src: url(../public/Font/open_dyslexic/OpenDyslexic-Regular.otf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: OpenDyslexic-Bold;
    src: url(../public/Font/open_dyslexic/OpenDyslexic-Bold.otf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: OpenDyslexic-Italic;
    src: url(../public/Font/open_dyslexic/OpenDyslexic-Italic.otf) format('truetype');
    font-display: swap;
}

@font-face {

    font-family: Roboto;
    src: url(../public/Font/Roboto/Roboto-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Roboto-light;
    src: url(../public/Font/Roboto/Roboto-Light.ttf) format('truetype');
    font-display: swap;
}

@font-face {

    font-family: Roboto-medium;
    src: url(../public/Font/Roboto/Roboto-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {

    font-family: Roboto-bold;
    src: url(../public/Font/Roboto/Roboto-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {

    font-family: Roboto-black;
    src: url(../public/Font/Roboto/Roboto-Black.ttf) format('truetype');
    font-display: swap;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.lead{
    a{
        color: #121f37 ;
        // font-style: italic !important;
        font-weight: 700;
        text-decoration: underline #EB1930 !important;
    }
}

.timeline-item {
    a {
        color: #ffffff !important;
        // font-style: italic !important;
        font-weight: 600;
        text-decoration: underline #EB1930 !important;
    }
}

.author-name {
    font-family: 'NunitoSans-Bold';
    font-style: italic;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

.author-nam {
    font-family: 'NunitoSans-Bold';
    font-style: italic;
}

.author-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;
    font-size: 18px;
    font-family: 'NunitoSans-Regular';
}

.author-det {
    font-family: 'NunitoSans-Regular';
}

.hide {
    display: none;
}

.p {
    font-family: 'NunitoSans-Regular';
    margin-bottom: 1rem;
}

.section-title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: 'NunitoSans-Bold';
}

.ref-list {
    margin-top: 20px;
}

.ref-title {
    margin-top: 10px;
    margin-bottom: 10px;
    // font-family: 'NunitoSans-Bold';
    font-family: Times New Roman, Times, serif;
    font-weight: 600;
    font-size: 24px;
}

.ref {
    display: flex;
    font-family: 'NunitoSans-Regular';

    span {
        width: 30px;
        padding-left: 10px;
        display: flex;
        justify-content: flex-end;
    }

    p {
        margin-left: 10px;
        word-break: break-all;
    }
}

.ext-link {
    color: black;
}

.break-ref {
    margin-bottom: 1rem;
}

.fig {
    background-color: #f5f5f5;
    padding: 15px;
    margin-bottom: 2rem;
    border-left: 4px solid #465063;
    border-radius: 15px;
    max-width: 600px;
    max-height: fit-content;
    object-fit: cover;
}

.fig-caption {
    margin-top: 15px;
    font-family: 'NunitoSans-Regular';
}

.figure {
    font-family: 'NunitoSans-Bold';
}

.small-image {
    // object-fit: cover;
    width: 300px;
    // height: 300px;
    margin-bottom: 1rem;
    border-radius: 10px;
    box-shadow: rgb(189 189 189) 4.4px 4.4px 3.2px;
}

.bold {
    font-family: "NunitoSans-Bold";
}

.italic {
    font-family: "NunitoSans-Italic";
}

.sup {
    vertical-align: super;
    font-size: smaller;
}

.iframeclass {
    display: block;
    margin: 0px;
    width: 750px;
    height: 450px;
}

.vimeo_widget {
    padding-top: 1rem !important;
}

.table-title {
    font-size: 24px;
    font-family: 'NunitoSans-Bold';
}

.table-caption {
    font-size: 18px;
    font-family: 'NunitoSans-Bold';
}

.table-content {
    border-radius: 15px;
    margin-top: 20px;
    font-family: 'NunitoSans-Regular';
}

.table-content thead {
    background-color: #141b2e;
    color: white;
}

.table,
.th,
.td {
    border: 3px solid #ffffff;
    padding: 20px 60px 20px 60px;
}

.table .td {
    background-color: #f3f3f3;
}

.table {
    border-radius: 1em;
    overflow: hidden;
    width: 100%;
}

.table .td:first-child {
    width: 100px;
}

.table .th:first-child {
    width: 100px;
}

.read-more {
    // width: 335px !important;
    margin-top: 18px;
    font-family: 'NunitoSans-Bold';
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #141b2e;
    border-radius: 30px;
    color: #ffffff;
    width: fit-content;
    display: flex;
    justify-content: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    a {
        color: #ffffff;
        text-decoration: none;
    }
}

.twitter-tweet {
    position: static;
    visibility: visible;
    width: 500px;
    // height: 871px;
    display: block;
    flex-grow: 1;
}

.bjsclass {
    display: block;
    margin: 0px;
    width: 750px;
    height: 300px;
}

.h2-block-heading {
    font-family: Nunito Sans, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.188rem;
    letter-spacing: .2px;
    margin-top: 40px !important;
}

.link {
    margin-bottom: 1.5rem;
}

.dyslexic-regular {

    .author-name,
    .author-content,
    .author-det,
    .p,
    .section-title .ref-title,
    .ref,
    .fig-caption,
    .figure,
    .table-title,
    .table-caption,
    .table-content,
    .article-content,
    .article-content .article .body .p,
    .back .ref-list .p .bold,
    .article-content .article .body .p .bold,
    .italic,
    .article-content .article .back .ref-list .p,
    .article-content .article .back .ref-list .title,
    .about-article,
    .chapter-heading,
    .chapter-content,
    .chapter-author,
    .citation-text,
    .author-content,
    .chapter-title,
    .chapter-references,
    .chapter-references-tab,
    .timeline,
    .table-w-caption,
    .chapter-tab,
    .nav,
    .nav-tabs,
    .nav-link .active,
    .nav-item,.nav-link,
    .tl-item,    
    .timeline ,.block ,
    .related h2 {

        font-family: "OpenDyslexic-Regular" !important;
    }

    .bold,
    .read-more {
        font-family: "OpenDyslexic-Bold" !important;
    }

    .italic,
    .article-content .article .body .p .italic {
        font-family: "OpenDyslexic-Italic" !important;
    }
}

.article-content .article {

    .body .p,
    .back .ref-list .p {
        line-height: 1.75 !important;
        font-size: 20px !important;
        font-family: Times New Roman, Times, serif !important;
        word-break: break-word !important;

        .bold {
            font-weight: 600 !important;
            font-family: Times New Roman, Times, serif !important;
        }

        .italic {
            font-style: italic !important;
            font-family: Times New Roman, Times, serif !important;
        }
    }

    .disp-quote .p {
        font-size: 24px !important;
        line-height: 1.5rem !important;
        letter-spacing: .2px !important;
        font-style: italic;
    }

    .body [attr-content-type="h2-block-heading"] {
        font-size: 22px !important;
        font-weight: 600 !important;
        margin-bottom: 2px !important;
    }
}

.skeleton {
    opacity: .7;
    cursor: progress;
    background: linear-gradient(90deg, rgb(223 223 223 / 75%) 40%, rgba(255, 254, 252, 0.93), rgba(223 223 223 / 75%) 60%) right / 300% 100%;
    animation: skeleton-loading 1.5s linear infinite;
}

.skeleton-text {
    width: 100%;
    height: 1rem;
    margin-bottom: .60rem;
    border-radius: .125rem;
}

.skeleton-text-80 {
    width: 80%;
}

.skeleton-author {
    height: 25px;
    margin-bottom: 10px;
}

@keyframes skeleton-loading {
    to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}

.graphic:hover {
    cursor: pointer;
}

.text-content hr {
    width: 10%;
}

.section__copy .hr--color {
    width: 36px;
}

.text-block a,
.image-content a {
    color: black;
}

.text-content a {
    color: $text-color-content-management;
}
.text-content a, .header a ,.content a ,.content-desc a{
    color: var(--content-a-colorr);
}
.large li a{
    color: var(--content-a-color);
}

.content-inner-desc {
    a {
        color: var(--color) !important;
    }
}
.chapter-list-container ol,ul{
    padding-left: 1rem !important;
}
//*************************************style for content management ***********************
.field-item  h2{
    font-family: Roboto;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
}
.about-us-container h1{
    font-family: Roboto-bold;
    font-size: 1.9rem;
}
.about-us-container h1{
    color: $primary_color;

}

.field-item p em span{
    color: $primary_color;
}
